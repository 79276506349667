import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import set from "lodash/set";
import isString from "lodash/isString";

import { toUppercaseValues } from "../../../util/object";

export default fields => (state, action) => {
  switch (action.type) {
    case "@@redux-form/INITIALIZE": {
      const values = cloneDeep(action.payload);
      const newValues = fields
        ? fields.reduce((acc, field) => {
            const val = get(values, field);

            if (isString(val)) {
              set(acc, field, val.toUpperCase());
            }

            return acc;
          }, values)
        : toUppercaseValues(values);

      return {
        ...state,
        values: newValues,
      };
    }
    case "@@redux-form/BLUR":
    case "@@redux-form/AUTOFILL":
      if (
        isString(action.payload) &&
        (!fields || fields.includes(action.meta.field))
      ) {
        return {
          ...state,
          values: set(
            cloneDeep(state.values),
            action.meta.field,
            action.payload.toUpperCase()
          ),
        };
      }
      return state;
    default:
      return state;
  }
};
